<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <router-view />
  </div>
</template>
<script>


  import Loading from '@/Components/Loading.vue'
  import { mapMutations ,mapState } from "vuex";
  export default {
    computed: {
      ...mapState(['LOADING','state'])
    },
    name: 'App',
    components: {Loading},
    // 国际化方案适配
    methods: {
        autoChange() {
          switch (navigator.language) {
           case "en":
             this.$i18n.locale = "en-US";
             break;
            case "zh-CN":
             this.$i18n.locale = "zh-CN";
             break;
        }
             },
    GetQueryString() {
       let arr=window.location.search.substring(1).split('=')[1]
      
       if(arr=='zh' || arr== "true&lang"){
        this.$i18n.locale = "zh-CN";
       }else if(arr=='en' || arr=="true&langen"){
        this.$i18n.locale = "en-US";
       }else{
        this.$i18n.locale = "zh-CN";
       }
    },
            //  安卓苹果调用token
     initToken(token){
      
      this.setToken(token)
     },
     ...mapMutations(['setToken' ,'delToken'])
      },

    mounted(){
      // const token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjg0MDkyMDMxNzE3NDA4NzY5IiwiaWF0IjoxNzA0MzQxMzkwLCJleHAiOjE3MDk1MjUzOTB9.PholNRKgOYFQMo075UvGpuOlc9Qgrn87yTs81_kYJOknV7cpXNqx4kMRbnOCM9LvrjCqdGtTn4Feha1cUNmUaw'
      // const token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjgyMzMzOTcwOTk2NzU2NDgyIiwiaWF0IjoxNjkwMzUyNTUxLCJleHAiOjY4NzQzNTI1NTF9.hCHjjG1Lgu-U2oKGeiFWlHlaPNtr5bXIj2GGvgOcVFNkar5nLlHRRrnoJ5eKeHfrhv8NKNlLO3WROC1RIVCeOg'
      // const token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjQ2NzE3OTE3MTg0OTE3NTA2IiwiaWF0IjoxNjg2NzA4NzI3LCJleHAiOjY4NzA3MDg3Mjd9.ApQJ_xaLcHzcfneQVjvOaKvWF3nVruqMfm90CDSEnYQSrFFWfkLSypTjNxpUNtbsBJ-usM4lwRhj1HLhIMAsgQ'
      // const token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNTQ2Mzg4Mzc3ODE0Mjg2MzM3IiwiaWF0IjoxNjg1Njg2NDYxLCJleHAiOjY4Njk2ODY0NjF9.w4uCApK8NFvtS1MhT1HHj01XkNN2Uruw8JQobulC-oNeKHB-nYLB8jyncM2DbJjUDhj4HsQBOYzjH5b69t5Ttw'
      // const token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjU1NDg4NjE5NzQ0Njc3ODg5IiwiaWF0IjoxNjg1OTMxNTQwLCJleHAiOjY4Njk5MzE1NDB9.XY-dcRVEjscmDCc0sZ9lUoq8Gy_ejA8-7P_G5gGwIK0BPG3bPY7mzBjFvlo5Asj9SaI9xSLWWyJarga_4TGwKQ'
      // this.setToken(token)
      // 获取token值
        window.initToken=this.initToken
            if (isAndroid) {
              Android.getToken()
                    }
          if (isIOS) {
      window.webkit.messageHandlers.getToken.postMessage(1)

   }
      },
    created() {
      if (isAndroid) {
        this.autoChange()
                    }
      if (isIOS) {
        this.GetQueryString()

   }
   if(!isAndroid&&!isIOS){
    this.autoChange()
   }
     
 },
  }
</script>
<style lang="less">
  #app{
    padding-top:50px;
    height:100%;
  }
  .van-nav-bar__content{
    height:50px !important;
  }

    .van-nav-bar{
     padding-top: constant(safe-area-inset-top);
     padding-top: env(safe-area-inset-top);

  }
  .van-nav-bar__left{
    .van-icon-arrow-left{
      color:#030000;
      font-weight: 600;
    }
  }
  .van-nav-bar__content{
    .van-nav-bar__title{
      color:#333;
      font-weight: 600;
    }  
  }

  .van-icon-arrow-left{
    font-size: 0.56rem !important;
    color: #000 !important;
}
.van-loading {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.van-overlay{
  background-color:rgba(0,0,0,.5) !important;
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
       color: #666!important;
       }
     :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color: #666!important;
       }
     ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: #666!important;
       }
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #666!important;
     }
</style>
